import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 物品信息
    from:'', // 来自
    destination: '', // 目的地
    itemInfo:{
      //发出地
      whereSend: "",
      //接收地
      whereReceive: "",
      //重量
      weight: "",
      //长度
      length: "",
      //宽度
      width: "",
      //高度
      height: "",
    },
    // 用户信息
    userInfo:{},
    lineId:'', // 服务路线id
    dialog:false,
    // 批量下单操作步骤
    bulkStep:1,
    // 单次下单操作步骤
    step:1,
    // 省市区三级联动值
    cityOption:[],

    // 需要支付的参数
    paymentObj:{
      ids:[],
      price:"",
      sn:"",
      discountCode: ""
    },
    // 订单支付id
    payId:'',
    // 我的账户控制显示字段
    curShowType:0,
    // 默认保险方案
    insurance:'',
    // 保险下拉框值
    InsuranceOptions:[],
  },
  getters:{
    // 获取目的地
    getDest(state){
      return state.destination;
    },
    // 获取物品信息
    getItemInfo(state){
      return state.itemInfo
    },
    // 获取用户信息
    getUserInfo(state){
      return state.userInfo
    },
    // 获取服务路线id
    getLineId(state){
      return state.lineId
    }
  },
  mutations: {
    // 修改出发地
    SET_FROM(state, data){
      state.from = data;
    },
    // 修改目的地
    SET_DEST(state, data) {
      state.destination = data;
    },
    // 修改商品信息
    SET_ITEM_INFO(state, data = {whereSend:'', whereReceive:'',weight:'', length:'', width:'', height:''}){
      state.itemInfo = data;
    },
    // 修改用户信息
    SET_USER_INFO(state, data){
      state.userInfo = data;
    },
    // 保存服务路线id
    SET_LINE_ID(state, data){
      state.lineId = data;
    }
  },
  // 一般跟接口相关
  actions: {
    updateDest({ commit }, data) {
      commit('SET_DEST', data);
    },
    updateItemInfo({ commit }, data) {
      commit('SET_ITEM_INFO', data)
    },
    // 修改用户信息
    updateUserInfo({ commit }, data) {
      commit('SET_USER_INFO', data)
    },
    // 保存服务路线id
    updateLineId({ commit }, data){
      commit('SET_LINE_ID',data)
    }
  },
  modules: {
  }
})
