import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from "@components/layout/layout.vue";
Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect: '/Home'
  },
  { 
    path: '/tutorials',
    name: 'tutorials',
    component: () => import('@views/tutorials.vue'),
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        
        path: 'Home',
        name: 'Home',
        component: () => import('@views/Home.vue'),
        /** name 顶部菜单名称
         *  path 当前菜单对应的路径
         *  parentPath 当前菜单父级路径
         */
        meta:{
          name: "首页",
          path: "/Home",
          parentPath: "/",
        }
      },
 
      {
        path:'/orderNow',
        name: 'OrderNow',
        component: () => import('@views/order/orderNow.vue')
      },
      {
        path:'/BulkOrder',
        name: 'BulkOrder',
        component: () => import('@views/bulkOrder/BulkOrder.vue')
      },
      {
        path:'/FreightQuery',
        name:'FreightQuery',
        component: () => import('@views/FreightQuery.vue')
      },
      {
        path:'/ShoppingCart',
        name:'ShoppingCart',
        component: () => import('@views/ShoppingCart.vue')
      },
      {
        path:'/MyAccount',
        name:'MyAccount',
        component: () => import('@views/myAccount/AccountInformation.vue')
      },
      {
        path:'/commonProblem',
        name:'commonProblem',
        component: () => import('@views/commonProblem.vue')
      },
      {
        path:'/paymentMethod',
        name:'paymentMethod',
        component: () => import('@views/paymentMethod.vue')
      },
      {
        path:'/SKU',
        name:'SKU',
        component: () => import('@views/SKU.vue')
      },
      {
        path:'/news',
        name:'news',
        component: () => import('@views/news/list.vue'),
      },
      {
        path:'/news/detail',
        name:'newsDetail',
        component: () => import('@views/news/detail.vue')
      }
    ],
  },
  // 邀请码页面  ---- 以后可能删除
  // {
  //   path:'/invitationCode',
  //   name:'invitationCode',
  //   component:() => import('@views/invitationCode.vue'),
  //   // isLogin 是否调用判断登录接口   邀请码页面不判断
  //   meta:{isLogin:true},
  // },
  {
    path:'/IdCardUpload',
    name:'IdCardUpload',
    component: () => import('@views/IdCardUpload.vue')
  },
  {
    path:'/pdf',
    name:'pdf',
    component: () => import('@views/pdf.vue')
  },
  {
    path:'/track',
    name:'track',
    component: () => import('@views/track.vue')
  },
  {
    path:'/trackOrder',
    name:'trackOrder',
    component: () => import('@views/trackOrder.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
