import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.100:7000/express`
// 获取国际信息
export function getPhoneGlobalRoamingList(params) {
  return http.get(`${resquest}/userCustomer/getPhoneGlobalRoamingList`, params);
}

// 获取验证码
export function getVerificationCode(data) {
  return http.post(`${resquest}/userCustomer/getVerificationCode`, data);
}
// 下一步检测 用户名，手机号验证码
export function checkRegisterUserInfo(data) {
  return http.post(`${resquest}/userCustomer/checkRegisterUserInfo`, data);
}
// 验证手机号是否注册
export function checkPhone(params) {
  return http.post(`${resquest}/userCustomer/checkPhone`, params);
}
// 注册
export function register(data) {
  return http.post(`${resquest}/userCustomer/register`, data);
}
// 用户名或邮箱登录
export function loginUsername(data) {
  return http.post(`${resquest}/userCustomer/loginUsername`, data);
}
// 手机号登录
export function loginPhone(data) {
  return http.post(`${resquest}/userCustomer/loginPhone`, data);
}

// 忘记密码
export function forgetPassword(data) {
  return http.post(`${resquest}/userCustomer/forgetPassword`, data);
}

// 检查登录状态
export function isLogin(data) {
  return http.get(`${resquest}/userCustomer/isLogining`, data);
}

//获取用户信息
export function getUserInfo() {
  return http.get(`${resquest}/userCustomer/getUserInfo`);
}

// 修改用户信息
export function updateUserInfo(data) {
  return http.post(`${resquest}/userCustomer/updateUserInfo`, data);
}


// 快捷登录转运
export function getTransportToken() {
  return http.get(`${resquest}/userCustomer/getTransportToken`);
}
// 快速登录
export function quickLogin(data) {
  return http.post(`${resquest}/userCustomer/quickLogin`, data);
}