import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// less 全局变量
import '@assets/css/common.less'
// 重置浏览器默认样式
import '@assets/css/common.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/permission' // permission control

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
