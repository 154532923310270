<template>
  <div class="login-dialog">
    <el-dialog
      :show-close="false"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      z-index="1000"
      :before-close="close"
      width="854px">
      <div class="login-main">
        <div class="login-leftImg">
          <img src="@/assets/img/login/fb-login-bg.png" alt="" style="width:100%">
        </div>
        <div class="login-right">
          <Login 
            v-if="status === 'login'"
            @goRegister="(status) => $emit('goRegister', status)"
            @close="(res) => $emit('closeLoginDialog', res)"></Login>
          <Register 
            v-if="status === 'register'"
            @goRegister="(status) => $emit('goRegister', status)"></Register>
          <ForgetPassword
            v-if="status === 'forget'"
            @goRegister="(status) => $emit('goRegister', status)"></ForgetPassword>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 登录
import Login from "./login.vue"
// 注册
import Register from "./register.vue"
// 忘记密码
import ForgetPassword from "./forgetPassword.vue"
export default {
  name:'loginDialog',
  components:{Login, Register,ForgetPassword},
  props:{
    dialogVisible:{
      type: Boolean,
      default: false
    },
    status:{
      type: String,
      default: 'login'
    }
  },
  data(){
    return {
      // dialogVisible:false,
    }
  },
  methods: {
    // 关闭弹框
    close(){
      this.$emit('closeLoginDialog', false);
    }
  }
}
</script>
<style lang="less" scoped>
// 输入框宽度改为44px
.login-dialog::v-deep .el-input__inner{
  height: 44px;
}
.login-dialog::v-deep .el-input .el-input__icon{
    line-height: 44px;
    font-size: 16px;
    color: #B1B6C3;
  }
.login-dialog::v-deep .el-button{
  height: 44px;
}
// 去掉弹框的边距
.login-dialog::v-deep .el-dialog__header, 
.login-dialog::v-deep .el-dialog__body{
  padding:0 !important;
}
// 弹框边角
.login-dialog::v-deep .el-dialog{
  border-radius:8px;
}
</style>
<style lang="less" scoped>
.login-main{
  display:flex;
  .login-leftImg{
    height:630px;
    width:390px;
    background-color:#6D5EFE;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }
  .login-right{
    flex:1;
    padding: 0px 70px;
  }
}

</style>