<template>
  <div class="layout">
    <HeaderDom></HeaderDom>
    <div class="content">
      <!-- 悬浮按钮 -->
      <div class="right-btn">
        <div class="btn-item btn-wechat posi">
          <img
            width="32px"
            class="btn-icon"
            src="../../assets/img/home/icon_wechat_nor.png"
          />
          <div class="btn-name">官方微信</div>
          <div class="vCode vCart">
            <img
              src="../../assets/img/wechatCode.png"
              class="wechatImg"
              alt=""
            />
          </div>
        </div>
        <div class="btn-line"></div>
        <div class="btn-item btn-email posi">
          <img
            width="32px"
            class="btn-icon"
            src="../../assets/img/home/icon_email_nor.png"
          />
          <div class="btn-name">客服邮箱</div>
          <div class="vCode email">info@gocn.co.uk</div>
        </div>
      </div>
      <router-view :key="key" />
      <div style="text-align: center;
    font-size: 18px;
    color: #b8b5c6;
    ">WELL LOGISTICS LTD: 13811199</div>
    </div>
    
  </div>
</template>
<script>
import HeaderDom from "@components/header/header.vue";
export default {
  name: "layout",
  components: { HeaderDom },
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    key() {
      this.$nextTick(() => {
        document.querySelector(".content").scrollTop = 0;
      });

      return this.$route.path;
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.layout {
  height: 100%;
}
.content {
  height: calc(100% - 101px);
  min-width: 1400px;
  overflow-y: scroll;
  //右侧悬浮按钮
  .right-btn {
    position: fixed;
    top: 75%;
    right: 0px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 100;
    align-items: center;
    width: 76px;
    height: 152px;
    background: #ffffff;
    box-shadow: 0px 6px 12px 0px rgba(17, 23, 58, 0.1);
    border-radius: 8px 0px 0px 8px;
    //按钮item
    .btn-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      justify-items: center;
      width: 100%;
      .btn-name {
        height: 18px;
        font-size: 13px;
        font-weight: 400;
        color: #636d88;
        line-height: 18px;
        text-shadow: 0px 6px 12px rgba(17, 23, 58, 0.1);
        margin-top: 7px;
      }
      .vCode {
        display: none;
        position: absolute;
        width: 180px;
        height: 180px;
        // background: #6d5efe;
        left: -180px;
        top: 50%;
        transform: translateY(-50%);
      }
      .email {
        width: 150px;
        left: -150px;
      }
    }
    .btn-item:hover div {
      color: #6d5efe;
    }
    .btn-wechat:hover .vCart {
      display: block;
    }
    .btn-wechat:hover .vCart .wechatImg {
      width: 200px;
      left: -22px;
      position: absolute;
      border-radius: 10px;
      top: 50px;
    }
    .btn-wechat:hover .btn-icon {
      content: url("../../assets/img/home/icon_wechat_hov.png");
    }
    .btn-wechat:hover .btn-icon {
      content: url("../../assets/img/home/icon_wechat_hov.png");
    }
    .btn-email:hover img {
      content: url("../../assets/img/home/icon_email_hov.png");
    }
    .btn-email:hover .email {
      display: block;
      color: #6d5efe;
      left: -200px;
      display: block;
      background: no-repeat;
      color: #6d5efe;
      font-size: 18px;
      font-weight: 600;
      transform: translateX(-1%) translateY(-49%);
      background: #ffffff;
      box-shadow: 0px 6px 12px 0px rgb(17 23 58 / 10%);
      width: 200px;
      text-align: center;
      height: 76px;
      border-radius: 10px;
      line-height: 76px;
    }
    //分割线
    .btn-line {
      width: 51px;
      height: 1px;
      border-top: 1px dashed #eff0f3;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    //
  }
}
</style>
