import http from "@/utils/http";

const resquest = `${process.env.VUE_APP_BASE_URL}`;
// const resquest = `http://192.168.1.229:7000/express`

// 去充值
export function createPayment(data) {
  return http.post(`${resquest}/user/payment/createPayment`, data);
}

// 用户余额明细查询
export function listUserAmountRecord(data) {
  return http.post(`${resquest}/userInfo/amountChangeRecord/listUserAmountRecord`, data);
}

// 用户订单查询
export function listOrder(data) {
  return http.post(`${resquest}/frontend/orderPackage/listOrder`, data);
}

//面单管理查询
export function listFace(data) {
  return http.post(`${resquest}/frontend/orderPackage/listFace`, data);
}

//标记为已打印
export function updateFacePrintStatus(data) {
  return http.post(`${resquest}/frontend/orderPackage/updateFacePrintStatus`, data);
}


//查询包裹列表
export function getRecentOrderPackage() {
  return http.get(`${resquest}/frontend/orderPackage/getRecentOrderPackage`);
}

//站内信列表
export function showMailToUserByPage(data) {
  return http.post(`${resquest}/user/siteMail/showMailToUserByPage`, data);
}

//站内信详情
export function showMailContent(data) {
  return http.get(`${resquest}/user/siteMail/showMailContent`, data);
}

//站内信已读
export function readedMailById(data) {
  return http.get(`${resquest}/user/siteMail/readedMailById`, data);
}

//检测是否有新站内信
export function checkHaveNewMail() {
  return http.get(`${resquest}/user/siteMail/checkHaveNewMail`);
}

//订单详情
export function getOrderPackageListDetailById(data) {
  return http.get(`${resquest}/frontend/orderPackage/getOrderPackageListDetailById`, data);
}

// 查看面单地址
export function showFaceSheetById(params) {
  return http.get(`${resquest}/frontend/orderPackage/showFaceSheetById`,params)
}

//查看合并面单地址
export function showMergeFaceSheetByIds(data) {
  return http.post(`${resquest}/frontend/orderPackage/showMergeFaceSheetByIds`, data);
}

//包裹追踪
export function showAllRecordByOrderPackageId(data) {
  return http.get(`${resquest}/front/packageLogisticsRecord/showAllRecordByOrderPackageId`, data);
}

//根据订单号面单号邮单号进行包裹追踪
export function getOrderIdByNumber(data) {
  return http.get(`${resquest}/front/packageLogisticsRecord/getOrderIdByNumber`, data);
}

//投诉反馈
export function addComplaintFeedback(data) {
  return http.post(`${resquest}/user/complaintFeedback/addComplaintFeedback`, data);
}

//账户余额
export function getUserAmount() {
  return http.get(`${resquest}/userCustomer/getUserAmount`);
}