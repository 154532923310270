<template>
  <div class="forget">
    <div class="forget-title">修改密码</div>
    <!-- 忘记密码表单 -->
    <el-form
      class="forget-form"
      ref="forgetForm"
      :model="userForm"
      :rules="rules"
    >
      <el-form-item prop="iphone" class="iphone-input">
        <img
          :src="
            userForm.country !== '+86'
              ? require('../../assets/img/login/fb-uk.png')
              : require('../../assets/img/login/fb-china.png')
          "
          class="country-img"
        />
        <!-- <el-input placeholder="请输入手机号" prefix-icon="el-icon-user" v-model="userForm.iphone" autocomplete="new-password"></el-input> -->
        <el-input placeholder="请输入手机号" v-model="userForm.iphone">
          <el-select
            v-model="userForm.country"
            slot="prepend"
            placeholder="请选择"
            class="country-select"
          >
            <el-option
              v-for="item in country_config"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            >
              <img :src="item.url" class="country-option-img" />
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <GetCode
          v-model="userForm.code"
          :phone="userForm.iphone"
          :globalRoaming="userForm.country"
          :checkPhoneFlag="false"
        ></GetCode>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          placeholder="密码"
          v-model="userForm.password"
          autocomplete="new-password"
          :type="type1"
        >
          <div slot="prefix" class="password1-slot"></div>
          <div
            slot="suffix"
            :class="
              !showPassword1 ? 'password-slot-close' : 'password-slot-open'
            "
            @click="showPassword1 = !showPassword1"
          ></div>
        </el-input>
      </el-form-item>
      <el-form-item prop="confirm_password">
        <el-input
          placeholder="确认密码"
          v-model="userForm.confirm_password"
          autocomplete="new-password"
          :type="type2"
        >
          <div slot="prefix" class="password2-slot"></div>
          <div
            slot="suffix"
            :class="
              !showPassword2 ? 'password-slot-close' : 'password-slot-open'
            "
            @click="showPassword2 = !showPassword2"
          ></div>
        </el-input>
      </el-form-item>
      <el-button
        class="next-btn"
        type="primary"
        @click="submitForm('forgetForm')"
        >确认修改</el-button
      >
      <div class="forget-box">
        <span class="forget-btn" @click="goRegister"
          ><i class="el-icon-back"></i
        ></span>
      </div>
    </el-form>
  </div>
</template>
<script>
import GetCode from "./getCode.vue";
import { forgetPassword } from "@/api/login/login";
export default {
  name: "register",
  components: { GetCode },
  data() {
    // 校验密码
    var passwordReg = /^[a-z0-9!?+-]{6,18}$/i;
    var validatePassword = (rule, value, callback) => {
      if (!this.userForm.password) {
        callback(new Error("请输入密码"));
      }
      if (!passwordReg.test(value)) {
        callback(new Error("密码仅支持6至18位的数字、字母、标点符号"));
      } else {
        callback();
      }
    };
    // 重复密码校验
    var validatePassword2 = (rule, value, callback) => {
      if (!this.userForm.confirm_password) {
        callback(new Error("请输入密码"));
      }
      if (this.userForm.confirm_password !== this.userForm.password) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    };
    return {
      // 表单信息
      userForm: {
        // 手机号
        iphone: "",
        // 手机号国家
        country: "+44",
        // 验证码
        code: "",
        // 密码
        password: "",
        // 确认密码
        confirm_password: "",
      },
      showPassword1: false,
      showPassword2: false,
      // 已阅读
      read: true,
      // 是否可以点击下一步
      next_status: true,
      // 表单校验
      rules: {
        password: [
          { required: true, validator: validatePassword, trigger: "blur" },
        ],
        confirm_password: [
          { required: true, validator: validatePassword2, trigger: "blur" },
        ],
        code: [{ required: true, message: "验证码错误", trigger: "blur" }],
        iphone: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
        ],
      },
      // 国家手机号配置文件
      country_config: [
        {
          label: "China(中国) +86",
          value: "+86",
          url: require("../../assets/img/login/fb-china.png"),
        },
        {
          label: "United Kingdom +44",
          value: "+44",
          url: require("../../assets/img/login/fb-uk.png"),
        },
      ],
    };
  },
  computed: {
    type1() {
      return this.showPassword1 ? "text" : "password";
    },
    type2() {
      return this.showPassword2 ? "text" : "password";
    },
  },
  methods: {
    // 确认修改
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 调用忘记密码接口
          this.forgetPassword();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 忘记密码接口
    forgetPassword() {
      let updata = {
        code: this.userForm.code,
        password: this.userForm.password,
        globalRoaming: this.userForm.country,
        phone: this.userForm.iphone,
      };
      forgetPassword(updata)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.goRegister();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    // login
    goRegister() {
      this.$emit("goRegister", "login");
    },
  },
};
</script>
<style lang="less" scoped>
// 去掉弹框的边距
.forget::v-deep .el-input .el-input__inner {
  border-radius: 22px;
}
.forget::v-deep .el-input__prefix {
  left: 15px;
}
// 文本框icon的宽度加大
.forget::v-deep .el-input__icon {
  width: 40px;
}
// 文本框前边边距加大
.forget::v-deep .el-input--prefix .el-input__inner {
  padding-left: 57px;
}
// 文本框后边边距加大
.forget::v-deep .el-input--suffix .el-input__inner {
  padding-right: 40px;
}
// 修改复选框文字默认/选中字体颜色
.forget::v-deep .el-checkbox,
.forget::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: @bg-color;
}
// 复选框颜色
.forget::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: @bg-color;
  border-color: @bg-color;
}
.forget::v-deep .el-checkbox__inner:hover {
  border-color: @bg-color;
}
// 输入框前边带组件的只有有变带圆角
.forget::v-deep .el-input-group .el-input__inner {
  border-radius: 0 22px 22px 0;
}
// 输入框前边下拉框边框样式
.forget::v-deep .el-input-group .country-select .el-input__inner {
  border-color: transparent !important;
}
// 输入框前边下拉框边框圆角
.forget::v-deep .el-input-group__prepend {
  border-radius: 22px 0 0 22px;
  background: #fff;
}
// 下拉框中选中文字颜色
.el-select-dropdown__item.selected {
  color: @bg-color;
}
</style>

<style lang="less" scoped>
.forget-title {
  margin-top: 70px;
  font-size: 24px;
  font-weight: 400;
  color: #1e2b4f;
}
// 表单
.forget-form {
  margin-top: 60px;
  .password1-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_mima.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password2-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_mimaqueren.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password-slot-close {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_eyeclose.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password-slot-open {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_eyeopen.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  // 马上注册，忘密码行
  .forget-box {
    position: absolute;
    bottom: 46px;
    margin-top: 58px;
    width: 336px;
    display: flex;
    justify-content: space-between;
    color: @text-color;
    // 马上注册按钮
    .forget-btn {
      cursor: pointer;
      font-weight: bold;
      display: inline-block;
      width: 44px;
      height: 44px;
      border: 2px solid #b1b6c3;
      border-radius: 50%;
      text-align: center;
      line-height: 42px;
      font-size: 28px;
      color: #b1b6c3;
      &:hover {
        color: @bg-color;
        border-color: @bg-color;
      }
    }
    // 忘记密码
    .forget:hover {
      color: @bg-color;
    }
  }
  // 手机号登录框
  .iphone-input {
    position: relative;
  }
  // 选中国家图片
  .country-img {
    width: 24px;
    // height: 14px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  // 国家下拉框选项
  .country-select {
    width: 120px;
    position: relative;
    padding-left: 36px;
  }

  // 下一步
  .next-btn {
    width: 100%;
    border-radius: 22px;
    background-color: @bg-color;
    border-color: @bg-color;
    &:disabled {
      background-color: #c2bbc8;
      border-color: #c2bbc8;
    }
  }
  // 上一步
  .prv-btn {
    width: 100%;
    border-radius: 22px;
    color: @bg-color;
    border: 1px solid @bg-color;
    text-align: center;
    height: 44px;
    margin-top: 24px;
    line-height: 44px;
  }
  // 我已阅读文字样式
  .yiyue {
    color: @bg-color;
    font-size: 14px;
    margin-left: 12px;
  }
}

.country-option-img {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
}
</style>
