<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { isLogin } from "@/api/login/login";
export default {
  name: "App",
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    // let track = sessionStorage.getItem("track");
    // 邀请码
    // let invitationCode = localStorage.getItem('inv');
    // if(invitationCode && !track){
    //   this.$nextTick(() =>{
    //     this.isLogin()
    //   })
    // }
  },
  methods: {
    isLogin() {
      isLogin()
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$store.dispatch("updateUserInfo", res.data.userInfo);
          } else {
            this.$store.dispatch("updateUserInfo", {});
            localStorage.removeItem("token");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("updateUserInfo", {});
          localStorage.removeItem("token");
        });
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  height: 100%;
}
</style>
