<template>
  <div class="register">
    <div class="register-tags">
      <div :class="{ 'register-active': step === 1 }">
        <span class="register-num">1</span>
        填写注册信息
      </div>
      <div :class="{ 'register-active': step === 2 }">
        <span class="register-num">2</span>
        完善个人信息
      </div>
    </div>
    <!-- 注册表单 -->
    <el-form
      class="register-form"
      :style="{ 'margin-top': step == 2 ? '100px' : '40px' }"
      ref="registerForm"
      :model="userForm"
      :rules="rules"
    >
      <!-- 注册表1 -->
      <div v-if="step === 1">
        <el-form-item prop="user_name">
          <el-input
            placeholder="用户名"
            v-model="userForm.user_name"
            autocomplete="new-password"
          >
            <div slot="prefix" class="userName-slot"></div>
          </el-input>
        </el-form-item>
        <el-form-item prop="iphone" class="iphone-input">
          <img
            :src="
              userForm.country !== '+86'
                ? require('../../assets/img/login/fb-uk.png')
                : require('../../assets/img/login/fb-china.png')
            "
            class="country-img"
          />
          <!-- <el-input placeholder="请输入手机号" prefix-icon="el-icon-user" v-model="userForm.iphone" autocomplete="new-password"></el-input> -->
          <el-input placeholder="请输入手机号" v-model="userForm.iphone">
            <el-select
              v-model="userForm.country"
              slot="prepend"
              placeholder="请选择"
              class="country-select"
            >
              <el-option
                v-for="item in country_config"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <img :src="item.url" class="country-option-img" />
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item v-if="userForm.country == '+44'" prop="code">
          <GetCode
            v-model="userForm.code"
            :phone="userForm.iphone"
            :globalRoaming="userForm.country"
          ></GetCode>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="密码"
            v-model="userForm.password"
            autocomplete="new-password"
            :type="type1"
          >
            <div
              slot="suffix"
              :class="
                !showPassword1 ? 'password-slot-close' : 'password-slot-open'
              "
              @click="showPassword1 = !showPassword1"
            ></div>
            <div slot="prefix" class="password1-slot"></div>
          </el-input>
        </el-form-item>
        <el-form-item prop="confirm_password">
          <el-input
            placeholder="确认密码"
            v-model="userForm.confirm_password"
            autocomplete="new-password"
            :type="type2"
          >
            <div
              slot="suffix"
              :class="
                !showPassword2 ? 'password-slot-close' : 'password-slot-open'
              "
              @click="showPassword2 = !showPassword2"
            ></div>
            <div slot="prefix" class="password2-slot"></div>
          </el-input>
        </el-form-item>
        <el-button
          class="next-btn"
          type="primary"
          @click="submitForm('registerForm')"
          :disabled="next_status"
          >下一步</el-button
        >
      </div>
      <!-- 注册表2 -->
      <div v-if="step === 2">
        <el-form-item prop="email">
          <el-input
            placeholder="Email"
            v-model="userForm.email"
            autocomplete="new-password"
          >
            <div slot="prefix" class="email-slot"></div>
          </el-input>
        </el-form-item>
        <!--<el-form-item prop="postCode">
          <el-input
            placeholder="英国邮编"
            v-model="userForm.postCode"
            autocomplete="new-password"
          >
            <div slot="prefix" class="postCode-slot"></div>
          </el-input>
        </el-form-item>-->
        <el-button
          class="next-btn"
          style="margin-top: 76px"
          type="primary"
          @click="submitForm('registerForm')"
          :disabled="next_status"
          >马上注册</el-button
        >
        <div class="prv-btn posi" @click="prv">上一步</div>
      </div>
      <div class="register-box">
        <span class="posi forget" v-if="step === 2">
          <el-checkbox v-model="read"></el-checkbox>
          <span class="posi yiyue">我已阅读并同意服务条款</span>
        </span>
        <span v-else></span>
        <span
          >已注册，<span class="register-btn" @click="goRegister"
            >login</span
          ></span
        >
      </div>
    </el-form>
  </div>
</template>
<script>
import GetCode from "./getCode.vue";
import { register, checkRegisterUserInfo } from "@/api/login/login";
export default {
  name: "register",
  components: { GetCode },
  data() {
    // 校验用户名， 中英文 不超过14个字符
    var userNameReg = /^[0-9a-zA-Z\u4e00-\u9fa5\s]{0,14}$/i;
    var validateUser = (rule, value, callback) => {
      if (!this.userForm.user_name) {
        callback(new Error("请输入用户名"));
      }
      if (!userNameReg.test(value)) {
        callback(new Error("请输入用户名，中英文数字均可，最长14个字"));
      } else {
        callback();
      }
    };
    // 校验密码
    var passwordReg = /^[a-z0-9!?+-]{6,18}$/i;
    var validatePassword = (rule, value, callback) => {
      if (!this.userForm.password) {
        callback(new Error("请输入密码"));
      }
      if (!passwordReg.test(value)) {
        callback(new Error("密码仅支持6至18位的数字、字母、标点符号"));
      } else {
        callback();
      }
    };
    // 重复密码校验
    var validatePassword2 = (rule, value, callback) => {
      if (!this.userForm.confirm_password) {
        callback(new Error("请输入密码"));
      }
      if (this.userForm.confirm_password !== this.userForm.password) {
        callback(new Error("密码不一致"));
      } else {
        callback();
      }
    };
    // 此处自定义校验英国邮编js逻辑
    var zipCodeReg =
      /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;
    var validatePhone = (rule, value, callback) => {
      if (!zipCodeReg.test(value)) {
        callback(new Error("请输入英国邮编"));
      } else {
        callback();
      }
    };
    var emailReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    var validateEmail = (rule, value, callback) => {
      if (!emailReg.test(value)) {
        callback(new Error("Email地址不合法"));
      } else {
        callback();
      }
    };
    return {
      // 步骤
      step: 1,
      // 表单信息
      userForm: {
        // 用户名邮箱
        user_name: "",
        // 手机号
        iphone: "",
        // 手机号国家
        country: "+44",
        // 验证码
        code: "",
        // 密码
        password: "",
        // 确认密码
        confirm_password: "",
        // 邮箱
        email: "",
        // 邮编
        postCode: "",
      },
      showPassword1: false,
      showPassword2: false,
      // 已阅读
      read: true,
      // 是否可以点击下一步
      next_status: false,
      // 表单校验
      rules: {
        user_name: [
          { required: true, validator: validateUser, trigger: "blur" },
        ],
        password: [
          { required: true, validator: validatePassword, trigger: "blur" },
        ],
        code: [{ required: true, message: "验证码错误", trigger: "blur" }],
        iphone: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
        ],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        
        confirm_password: [
          { required: true, validator: validatePassword2, trigger: "blur" },
        ],
      },
      // 国家手机号配置文件
      country_config: [
        {
          label: "China(中国) +86",
          value: "+86",
          url: require("../../assets/img/login/fb-china.png"),
        },
        {
          label: "United Kingdom +44",
          value: "+44",
          url: require("../../assets/img/login/fb-uk.png"),
        },
      ],
    };
  },
  computed: {
    type1() {
      return this.showPassword1 ? "text" : "password";
    },
    type2() {
      return this.showPassword2 ? "text" : "password";
    },
  },
  methods: {
    // 下一步
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // step 1 时是下一步
          if (this.step === 1) {
            // 检测输入是否正确
            this.checkRegisterUserInfo();
          } else if (this.read !== true) {
            this.$message({
              message: "请阅读并同意服务条款",
              type: "warning",
            });
          } else {
            this.register();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 下一步验证用户名、手机号、验证码正确性
    checkRegisterUserInfo() {
      let updata = {
        code: this.userForm.code,
        globalRoaming: this.userForm.country,
        phone: this.userForm.iphone,
        username: this.userForm.user_name,
      };
      if (this.userForm.country == "+86") {
        this.step = 2;
        return;
      }
      checkRegisterUserInfo(updata).then((res) => {
        if (res.data.code == 0) {
          this.step = 2;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 调用注册接口
    register() {
      let updata = {
        code: this.userForm.code,
        email: this.userForm.email,
        globalRoaming: this.userForm.country,
        password: this.userForm.password,
        phone: this.userForm.iphone,
        postCode: this.userForm.postCode,
        username: this.userForm.user_name,
      };
      register(updata)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.goRegister();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    // 上一步
    prv() {
      this.$refs["registerForm"].resetFields();
      this.step = 1;
    },
    // login
    goRegister() {
      this.$emit("goRegister", "login");
    },
  },
};
</script>
<style lang="less" scoped>
.register::v-deep .el-form-item__error {
  left: 22px;
}
.register::v-deep .el-input__prefix {
  left: 15px;
}
// 去掉弹框的边距
.register::v-deep .el-input .el-input__inner {
  border-radius: 22px;
}
// 文本框icon的宽度加大
.register::v-deep .el-input__icon {
  width: 40px;
}
// 文本框前边边距加大
.register::v-deep .el-input--prefix .el-input__inner {
  padding-left: 57px;
}
// 文本框后边边距加大
.register::v-deep .el-input--suffix .el-input__inner {
  padding-right: 40px;
}
// 修改复选框文字默认/选中字体颜色
.register::v-deep .el-checkbox,
.register::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: @bg-color;
}
// 复选框颜色
.register::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: @bg-color;
  border-color: @bg-color;
}
.register::v-deep .el-checkbox__inner:hover {
  border-color: @bg-color;
}
// 输入框前边带组件的只有有变带圆角
.register::v-deep .el-input-group .el-input__inner {
  border-radius: 0 22px 22px 0;
}
// 输入框前边下拉框边框样式
.register::v-deep .el-input-group .country-select .el-input__inner {
  border-color: transparent !important;
}
// 输入框前边下拉框边框圆角
.register::v-deep .el-input-group__prepend {
  border-radius: 22px 0 0 22px;
  background: #fff;
  border: 1px solid #d2d5dc;
  border-right: 0px;
}
// 下拉框中选中文字颜色
.el-select-dropdown__item.selected {
  color: @bg-color;
}
</style>
<style lang="less" scoped>
.register-tags {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  color: @text-color;
  align-items: center;
  // 注册步骤序号
  .register-num {
    display: inline-block;
    width: 32px;
    height: 32px;
    color: #fff;
    background-color: #d2d5dc;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    margin-right: 12px;
  }
  // 步骤选中效果
  .register-active {
    color: #1e2b4f;
    .register-num {
      background-color: @bg-color;
    }
  }
}
// 表单
.register-form {
  margin-top: 40px;
  .userName-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_people.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password1-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_mima.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password2-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_mimaqueren.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .email-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_email.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .postCode-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_postCode.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password-slot-close {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_eyeclose.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password-slot-open {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_eyeopen.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  // 马上注册，忘密码行
  .register-box {
    position: absolute;
    bottom: 46px;
    margin-top: 58px;
    width: 336px;
    display: flex;
    justify-content: space-between;
    color: @text-color;
    // 马上注册按钮
    .register-btn {
      color: @bg-color;
      cursor: pointer;
      font-weight: bold;
    }
    // 忘记密码
    .forget:hover {
      color: @bg-color;
    }
  }
  // 手机号登录框
  .iphone-input {
    position: relative;
  }
  // 选中国家图片
  .country-img {
    width: 24px;
    // height: 14px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  // 国家下拉框选项
  .country-select {
    width: 120px;
    position: relative;
    padding-left: 36px;
  }

  // 下一步
  .next-btn {
    width: 100%;
    border-radius: 22px;
    background-color: @bg-color;
    border-color: @bg-color;
    &:disabled {
      background-color: #c2bbc8;
      border-color: #c2bbc8;
    }
  }
  // 上一步
  .prv-btn {
    width: 100%;
    border-radius: 22px;
    color: @bg-color;
    border: 1px solid @bg-color;
    text-align: center;
    height: 44px;
    margin-top: 24px;
    line-height: 44px;
  }
  // 我已阅读文字样式
  .yiyue {
    color: @bg-color;
    font-size: 14px;
    margin-left: 12px;
  }
}

.country-option-img {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
}
</style>
