<template>
  <!-- <el-input :value="value" @input="handleInput" @change="handleChange"></el-input> -->
  <div class="ver-code-box">
    <el-input
      placeholder="请输入验证码"
      :value="value"
      @input="handleInput($event)"
      @change="handleChange"
    >
    </el-input>
    <el-button class="get-code" v-if="show" @click="checkPhone"
      >获取验证码</el-button
    >
    <el-button class="get-code ver-is-disabled" v-else>{{
      "已发送(" + count + ")"
    }}</el-button>
  </div>
</template>
<script>
import { getVerificationCode, checkPhone } from "@/api/login/login.js";
export default {
  props: {
    value: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    phone: {
      type: String,
      default: "",
    },
    globalRoaming: {
      type: [String, Number],
    },
    checkPhoneFlag: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 倒计时秒数
      count: "",
      timer: null,
      show: true,
      status: false, // 手机号是否可以发送验证码
    };
  },
  methods: {
    // 获取验证码
    getVerify() {
      if (this.status === false) {
        console.log(this.globalRoaming);
        console.log(this.phone);
        return false;
      } else {
        // 发送验证码接口
        this.getVerificationCode();
        // 倒计时60秒
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
    },
    // 获取验证码接口
    getVerificationCode() {
      let updata = {
        globalRoaming: this.globalRoaming,
        phone: this.phone,
      };
      getVerificationCode(updata)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 验证手机号
    checkPhone() {
      let phone = this.phone;
      // console.log(phone, 'phone')
      // 英国手机号码是10位，现在不校验手机号码位数
      // if(phone.length !== 11){
      //   this.status = false;
      //   return;
      // }
      if (!this.checkPhoneFlag) {
        this.status = true;
        this.getVerify();
        return;
      }
      checkPhone({ phone: phone, globalRoaming: this.globalRoaming })
        .then((res) => {
          if (res.data.code === 0) {
            this.status = true;
            // 获取验证码
            this.getVerify();
          } else {
            this.status = false;
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {
          this.status = false;
        });
    },
    handleInput($event) {
      this.$emit("input", $event);
    },
    handleChange(value) {
      //处理纯空格
      if (
        Object.prototype.toString.call(value) === "[object String]" &&
        value.trim() === ""
      ) {
        this.$emit("input", "");
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 获取验证码行
.ver-code-box {
  position: relative;
  .get-code {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 22px 22px 0;
    background-color: @bg-color !important;
    color: #fff !important;
    border-color: @bg-color;
    font-weight: 400;
    width: 112px;
  }
  .ver-is-disabled {
    background-color: #c2bbc8 !important;
    border-color: #c2bbc8 !important;
  }
}
</style>
