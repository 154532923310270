<template>
  <div class="login">
    <div class="login-tags">
      <span
        class="posi login-tags-btn"
        :class="{ active: tag == 0 }"
        @click="switchTag(0)"
        >账号登录</span
      >
      <span class="ver">|</span>
      <span
        class="posi login-tags-btn"
        :class="{ active: tag == 1 }"
        @click="switchTag(1)"
        >手机登录</span
      >
    </div>

    <el-form
      class="login-form"
      ref="loginForm"
      :model="userForm"
      :rules="rules"
    >
      <!-- 登录表单 -->
      <div v-if="tag == 0">
        <el-form-item prop="user_name">
          <el-input
            placeholder="用户名或邮箱"
            v-model="userForm.user_name"
            autocomplete="new-password"
          >
            <div slot="prefix" class="userName-slot"></div>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="密码"
            v-model="userForm.password"
            autocomplete="new-password"
            :type="type1"
          >
            <div slot="prefix" class="password1-slot"></div>
            <div
              slot="suffix"
              :class="
                !showPassword1 ? 'password-slot-close' : 'password-slot-open'
              "
              @click="showPassword1 = !showPassword1"
            ></div>
          </el-input>
        </el-form-item>
      </div>
      <!-- 手机号登录表单 -->
      <div v-if="tag == 1">
        <el-form-item prop="iphone" class="iphone-input">
          <img
            :src="
              userForm.country !== '+86'
                ? require('../../assets/img/login/fb-uk.png')
                : require('../../assets/img/login/fb-china.png')
            "
            class="country-img"
          />
          <!-- <el-input placeholder="请输入手机号" prefix-icon="el-icon-user" v-model="userForm.iphone" autocomplete="new-password"></el-input> -->
          <el-input placeholder="请输入手机号" v-model="userForm.iphone">
            <el-select
              v-model="userForm.country"
              slot="prepend"
              placeholder="请选择"
              class="country-select"
            >
              <el-option
                v-for="item in country_config"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <img :src="item.url" class="country-option-img" />
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="密码"
            v-model="userForm.password"
            autocomplete="new-password"
            :type="type2"
          >
            <div slot="prefix" class="password1-slot"></div>
            <div
              slot="suffix"
              :class="
                !showPassword2 ? 'password-slot-close' : 'password-slot-open'
              "
              @click="showPassword2 = !showPassword2"
            ></div>
          </el-input>
        </el-form-item>
      </div>
      <el-form-item class="remember_me">
        <el-checkbox v-model="remember_me">记住我</el-checkbox>
      </el-form-item>
      <el-button
        class="login-btn"
        type="primary"
        @click="submitForm('loginForm')"
        >登录</el-button
      >
      <div class="register-box">
        <span
          >尚未注册，<span class="register-btn" @click="goRegister"
            >马上注册</span
          ></span
        >
        <span class="posi forget" @click="goForget">忘记密码</span>
      </div>
    </el-form>
    <!-- 注册表单 -->
  </div>
</template>
<script>
import { loginUsername, loginPhone, isLogin } from "@/api/login/login";
export default {
  name: "login",
  data() {
    return {
      // 0 账号登录  1 密码登录
      tag: 0,
      // 用户名，邮箱登录表单
      userForm: {
        iphone: "",
        country: "+44",
        user_name: "",
        password: "",
      },
      showPassword1: false,
      showPassword2: false,
      // 记住我
      remember_me: true,
      // 表单校验
      rules: {
        user_name: [
          { required: true, message: "请输入用户名或邮箱", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入您的密码", trigger: "blur" },
        ],
        iphone: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
        ],
      },
      // 国家手机号配置文件
      country_config: [
        {
          label: "China(中国) +86",
          value: "+86",
          url: require("../../assets/img/login/fb-china.png"),
        },
        {
          label: "United Kingdom +44",
          value: "+44",
          url: require("../../assets/img/login/fb-uk.png"),
        },
      ],
    };
  },
  computed: {
    type1() {
      return this.showPassword1 ? "text" : "password";
    },
    type2() {
      return this.showPassword2 ? "text" : "password";
    },
  },
  methods: {
    // 切换登录方式
    switchTag(tag) {
      if (tag === this.tag) {
        return;
      }
      this.$refs["loginForm"].resetFields();
      this.tag = tag;
    },
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 用户名或邮箱登录
          if (this.tag == 0) {
            this.loginUsername();
          } else if (this.tag == 1) {
            // 手机号登录
            this.loginPhone();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 用户名或邮箱登录
    loginUsername() {
      let updata = {
        username: this.userForm.user_name,
        password: this.userForm.password,
      };
      loginUsername(updata)
        .then((res) => {
          console.log(" loginUsername ", res);

          if (res.data.code === 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            console.log("login success", res.data.data);
            localStorage.setItem("token", res.data.data.verification);
            localStorage.setItem(
              "accessTags",
              JSON.stringify(res.data.data.tags)
            );

            this.isLogin();
            this.$store.state.dialog = false;
            setTimeout(() => {
              location.reload();
            }, 500);
          } else {
            this.$message.error(res.data.message);
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 手机号登录
    loginPhone() {
      let updata = {
        globalRoaming: this.userForm.country,
        password: this.userForm.password,
        phone: this.userForm.iphone,
      };
      loginPhone(updata)
        .then((res) => {
          console.log(" loginPhone ", res);

          if (res.data.code === 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            localStorage.setItem("token", res.data.data.verification);
            localStorage.setItem(
              "accessTags",
              JSON.stringify(res.data.data.tags)
            );

            this.isLogin();
            this.$store.state.dialog = false;
            setTimeout(() => {
              location.reload();
            }, 500);
          } else {
            this.$message.error(res.data.message);
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取登录用户信息
    isLogin() {
      isLogin()
        .then((res) => {
          console.log(" isLogin ", res);
          if (res.data.code == 0 && this.remember_me) {
            this.$store.dispatch("updateUserInfo", res.data.userInfo);
          } else {
            this.$store.dispatch("updateUserInfo", {});
            localStorage.removeItem("token");
          }
          this.$emit("close", "success");
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("updateUserInfo", {});
          localStorage.removeItem("token");
          this.$emit("close", "success");
        });
    },
    // 马上注册
    goRegister() {
      this.$emit("goRegister", "register");
    },
    // 忘记密码
    goForget() {
      this.$emit("goRegister", "forget");
    },
  },
};
</script>
<style lang="less" scoped>
// 去掉弹框的边距
.login::v-deep .el-input .el-input__inner {
  border-radius: 22px;
}
.login::v-deep .el-input__prefix {
  left: 15px;
}
// 选中文本框边框颜色
.login::v-deep .el-input .el-input__inner:focus {
  border-color: @bg-color;
}
// 文本框icon的宽度加大
.login::v-deep .el-input__icon {
  width: 40px;
}
// 文本框前边边距加大
.login::v-deep .el-input--prefix .el-input__inner {
  padding-left: 57px;
}
// 文本框后边边距加大
.login::v-deep .el-input--suffix .el-input__inner {
  padding-right: 40px;
}
// 修改复选框文字默认/选中字体颜色
.login::v-deep .el-checkbox,
.login::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: @bg-color;
}
// 复选框颜色
.login::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: @bg-color;
  border-color: @bg-color;
}
.login::v-deep .el-checkbox__inner:hover {
  border-color: @bg-color;
}
// 输入框前边带组件的只有有变带圆角
.login::v-deep .el-input-group .el-input__inner {
  border-radius: 0 22px 22px 0;
}
// 输入框前边下拉框边框样式
.login::v-deep .el-input-group .country-select .el-input__inner {
  border-color: transparent !important;
}
// 输入框前边下拉框边框圆角
.login::v-deep .el-input-group__prepend {
  border-radius: 22px 0 0 22px;
  background: #fff;
  border: 1px solid #d2d5dc;
  border-right: 0px;
}
// 下拉框中选中文字颜色
.el-select-dropdown__item.selected {
  color: @bg-color;
}
</style>
<style lang="less" scoped>
.login-tags {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  .login-tags-btn {
    color: @text-color;
    font-size: 24px;
  }
  // 账号、手机号登录选中态
  .active {
    color: #1e2b4f;
  }
}
.login-form {
  margin-top: 100px;
  ::v-deep .el-form-item__error {
    left: 22px;
  }
  .remember_me {
    ::v-deep .el-form-item__content {
      line-height: 0px;
      margin-top: 8px;
    }
  }
  .userName-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_people.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password1-slot {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_mima.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password-slot-close {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_eyeclose.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  .password-slot-open {
    width: 40px;
    height: 100%;
    background: url("../../assets/img/login/fb_eyeopen.png");
    background-position: 7px 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
  }
  // 登录按钮
  .login-btn {
    margin-top: 76px;
    width: 100%;
    border-radius: 22px;
    background-color: @bg-color;
    border-color: @bg-color;
  }
  // 马上注册，忘密码行
  .register-box {
    margin-top: 58px;
    display: flex;
    justify-content: space-between;
    color: @text-color;
    // 马上注册按钮
    .register-btn {
      color: @bg-color;
      cursor: pointer;
      font-weight: bold;
    }
    // 忘记密码
    .forget:hover {
      color: @bg-color;
    }
  }
  // 手机号登录框
  .iphone-input {
    position: relative;
  }
  // 选中国家图片
  .country-img {
    width: 24px;
    // height: 14px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }

  // 国家下拉框选项
  .country-select {
    width: 120px;
    position: relative;
    padding-left: 36px;
  }
}

.country-option-img {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
}
// 中间竖线
.ver {
  width: 1px;
  color: #d2d5dc;
  margin: 0 6px 0 4px;
  font-size: 24px;
  height: 26px;
}
</style>
