<template>
  <div class="home-header">
    <!-- 导航菜单 -->
    <div
      class="home-header"
      :style="{ filter: $store.state.dialog ? 'blur(2px)' : 'none' }"
    >
      <!-- icon图标和logo -->
      <div class="head-portrait posi" @click="homeClicked">
        <img class="head-portrait-icon" src="../../assets/img/icon_logo.png" />
        <!-- <img class="head-portrait-title" src="../../assets/img/logo.png"> -->
      </div>
      <!-- home按钮 -->
      <div
        class="home-menu-home posi"
        :class="{ active: curRou === '/Home' }"
        @click="homeClicked"
      >
        首页
      </div>
      <!-- 运费查询按钮 -->

      <!-- <div
        class="home-menu-find posi"
        :class="{ active: curRou === '/FreightQuery' }"
        @click="freightQueryClicked"
      >
        运费查询
      </div> -->
      <!-- 新闻动态按钮 -->
      <div
        class="home-menu posi"
        :class="{ active: curRou === '/news' }"
        @click="news"
      >
        新闻动态
      </div>
      <!-- 国际转运按钮 -->
      <div
        class="home-menu posi"
        :class="{ active: curRou === '/transport' }"
        @click="transport"
      >
        国际转运
      </div>

      <!-- 查询单号输入框 -->
      <el-input
        v-model="sdInput"
        placeholder="输入订单号/国内邮单号追踪包裹"
        class="SD-track"
        :style="isLoginSuccess ? 'margin-left:144px' : 'margin-left:212px'"
      ></el-input>
      <div class="track-btn posi" @click="test">包裹追踪</div>
      <!-- 未登陆状态 -->
      <div class="loginOrRegDev" v-if="!isLoginSuccess">
        <span class="loginOrReg posi" @click="openDialog('login')">登录</span>
        <span class="ver">|</span>
        <span class="loginOrReg posi" @click="openDialog('register')"
          >注册</span
        >
      </div>
      <!-- 已经登陆状态 -->
      <div class="my-account-div" v-else>
        <!-- 我的账户按钮 -->
        <div class="my-account posi" @click="onMyAccountClicked">我的账户</div>
        <!-- 购物车icon -->
        <el-badge is-dot class="shopping-cart-icon posi">
          <img
            width="100%"
            src="../../assets/img/home/icon_shopping_cart_nor.png"
            @click="shoppingCartClicked"
          />
        </el-badge>
        <!-- 头像icon -->
        <el-popover
          placement="bottom-end"
          :visible-arrow="false"
          popper-class="my-edit-div"
          trigger="click"
        >
          <el-badge slot="reference" is-dot class="my-account-icon posi">
            <img
              width="100%"
              src="../../assets/img/account_img.png"
              @click="userEditVisible = !userEditVisible"
            />
          </el-badge>
          <!-- 点击头像后进行的div操作 -->
          <div>
            <!-- 用户名 -->
            <div class="my-welcome">{{ userdata.username }}，欢迎您!</div>
            <!-- 邮箱 -->
            <div class="my-email">{{ userdata.email }}</div>
            <div class="my-line"></div>
            <!-- 消息 -->
            <div class="message-div my-item posi">
              <img
                class="my-item-icon"
                src="../../assets/img/home/icon_message_nor.png"
              />
              <div class="my-item-content" @click="messageShow">
                消息（{{ messageCounts }}）
              </div>
            </div>
            <!-- 问题反馈 -->
            <div class="question-div my-item posi">
              <img
                class="my-item-icon"
                src="../../assets/img/home/icon_question_nor.png"
              />
              <div class="my-item-content">问题件反馈</div>
            </div>
            <!-- 退出账号 -->
            <div class="exit-div my-item posi">
              <img
                class="my-item-icon"
                src="../../assets/img/home/icon_exit_nor.png"
              />
              <div class="my-item-content" @click="signOut">退出账号</div>
            </div>
          </div>
        </el-popover>
      </div>
    </div>
    <!-- 登录注册弹框组件 -->
    <loginDialog
      :dialogVisible="$store.state.dialog"
      :status="status"
      @goRegister="(sta) => (status = sta)"
      @closeLoginDialog="closeLoginDialog"
    >
    </loginDialog>
  </div>
</template>
<script>
// @ is an alias to /src
import loginDialog from "@components/login/loginDialog.vue";
import { getOrderIdByNumber, checkHaveNewMail } from "@/api/user/user";
import { getTransportToken, isLogin } from "@/api/login/login";
import {queryMock} from "@/api/mock/mock";

export default {
  name: "headerDom",
  components: { loginDialog },
  data() {
    return {
      // 是否显示登录注册弹框 显示时打开滤镜，关闭时关闭滤镜
      loginDialogVisible: false,
      //追踪号输入的内容
      sdInput: "",
      // 登录或注册 login / register
      status: "register",
      //是否已经登陆
      // isLoginSuccess:false,
      //用户信息
      // userdata:{
      //   username:"",
      //   email:"",
      //   // messagecount: 10,
      // },
      //是否显示用户操作
      userEditVisible: false,
      //包裹追踪号
      orderId: "",
      //消息条数
      messageCounts: 0,
    };
  },
  computed: {
    curRou: function () {
      return this.$route.path;
    },
    dialog() {
      return this.$store.state.dialog;
    },
    isLoginSuccess() {
      let userInfo = this.$store.state.userInfo;
      if (userInfo.id) {
        return true;
      } else {
        return false;
      }
    },
    userdata() {
      return this.$store.state.userInfo;
    },
  },
  mounted() {
    // this.getUserInfo();
    this.checkHaveNewMail();
    console.log("header login");
    console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token") && !this.isLoginSuccess) {
      this.isLogin();
    }
  },
  methods: {
    // 获取登录用户信息
    isLogin() {
      isLogin()
        .then((res) => {
          console.log(" isLogin ", res);
          if (res.data.code == 0) {
            this.$store.dispatch("updateUserInfo", res.data.userInfo);
          } else {
            this.$store.dispatch("updateUserInfo", {});
            localStorage.removeItem("token");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("updateUserInfo", {});
          localStorage.removeItem("token");
        });
    },
    //未读消息条数
    checkHaveNewMail() {
      checkHaveNewMail().then((res) => {
        if (res.data.code === 0) {
          this.messageCounts = res.data.data.count;
        }
        console.log(res, "包裹追踪接口返回值");
      });
    },
    queryMock() {
      let data = {
        orderNum: this.sdInput
      }
      queryMock(data).then((res) => {
        if (res.data.code === 0) {
          
          
          let { href } = this.$router.resolve({
            path: "/trackOrder",
            query: { orderNum: res.data.data.orderNum},
          });
          window.open(href, "_blank");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },


    //包裹跟踪
    getOrderIdByNumber() {
      let update = {
        number: this.sdInput,
      };
      getOrderIdByNumber(update).then((res) => {
        if (res.data.code === 0) {
          this.orderId = res.data.data.orderId;
          console.log(this.orderId, "面单号");
          let { href } = this.$router.resolve({
            path: "/track",
            query: { id: this.orderId },
          });
          window.open(href, "_blank");
        } else {
          this.queryMock();
          //this.$message.error(res.data.message);
        }
      });
    },
    test() {
      
      this.getOrderIdByNumber();
    },
    // 获取用户信息
    getUserInfo() {
      let userInfo = this.$store.getters.getUserInfo;
      
    },
    //消息
    messageShow() {
      this.$router.push({
        path: "/MyAccount",
      });
      this.$store.state.curShowType = 6;
    },
    // 退出登录
    signOut() {
      this.$store.dispatch("updateUserInfo", {});
      localStorage.removeItem("token");
      this.getUserInfo();
      this.$router.push({
        path: "/Home",
      });
    },
    // 打开登录注册弹框
    openDialog(tag) {
      this.$store.state.dialog = true;
      this.status = tag;
      if (this.$store.state.dialog) {
        let home = document.querySelector(".content");
        console.log(home);
        home.style.filter = "blur(2px)";
      }
    },
    // 关闭登录注册弹框
    closeLoginDialog(res) {
      if (res === "success") {
        console.log("成功");
        this.$nextTick(() => {
          this.getUserInfo();
        });
      }
      this.$store.state.dialog = false;
      if (!this.$store.state.dialog) {
        let home = document.querySelector(".content");
        console.log(home);
        home.style.filter = "none";
      }
    },
    // 首页被点击
    homeClicked() {
      this.$router.push({
        path: "/",
      });
    },
    // 我的账户按钮被点击
    onMyAccountClicked() {
      this.$router.push({
        path: "/MyAccount",
      });
      this.$store.state.curShowType = 0;
    },
    // 运费查询按钮被点击
    freightQueryClicked() {
      this.$router.push({
        path: "/FreightQuery",
      });
    },
    news() {
      this.$router.push({
        path: "/news",
      });
    },
    transport() {
      getTransportToken()
        .then((res) => {
          if (res.data.code != 0) {
            return this.$message.error("无法跳转");
          }

          window.open(
            "https://www.gocn.co.uk/dsyexpress/index.php?c=login&f=quicklogin&token=" +
              res.data.data.token,
            "_self"
          );
        })
        .catch((err) => {
          console.log(err);
          return this.$message.error("无法跳转");
        });
    },

    // 购物车按钮被点击
    shoppingCartClicked() {
      this.$router.push({
        path: "/ShoppingCart",
      });
    },
  },
};
</script>
<style>
/* 点击我的头像后，进行操作的div */
.my-edit-div {
  background: #ffffff !important;
  box-shadow: 0px 2px 10px 0px rgba(37, 76, 151, 0.3) !important;
  border-radius: 8px !important;
  padding: 0px !important;
  border: 0px !important;
}
</style>
<style lang="less">
/* 点击我的头像后，进行操作的div */
.my-edit-div {
  width: 190px;
  height: 210px;
  /* 用户名显示 */
  .my-welcome {
    height: 22px;
    font-size: 16px;
    font-weight: bold;
    color: #1e2b4f;
    line-height: 22px;
    margin-top: 16px;
    margin-left: 24px;
  }
  /* 邮箱显示 */
  .my-email {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #d2d5dc;
    line-height: 17px;
    margin-top: 4px;
    margin-left: 24px;
  }
  /* line */
  .my-line {
    width: 149px;
    border-top: 1px dashed #eff0f3;
    margin-top: 16px;
    margin-left: 21px;
  }
  /* 操作item的公共样式 */
  .my-item {
    margin-left: 24px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* 操作item的icon */
    .my-item-icon {
      width: 16px;
      height: 18px;
      margin-right: 10px;
    }
    /* 操作item的content */
    .my-item-content {
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #636d88;
      line-height: 17px;
    }
  }
  .my-item:hover div {
    color: #6d5efe;
  }
  /* 消息item被触摸时 */
  .message-div:hover img {
    content: url(../../assets/img/home/icon_message_hov.png);
  }
  /* 问题反馈item被触摸时 */
  .question-div:hover img {
    content: url(../../assets/img/home/icon_question_hov.png);
  }
  /* 退出账号item被触摸时 */
  .exit-div:hover img {
    content: url(../../assets/img/home/icon_exit_hov.png);
  }
}
</style>
<style lang="less" scoped>
// 导航菜单
.home-header {
  background-color: #ffffff;
  height: 100px;
  border-bottom: 1px solid #e5daeb;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1400px;
  // width: 100%;

  // 头像
  .head-portrait {
    display: flex;
    align-items: center;
    justify-content: center;
    .head-portrait-icon {
      width: 157px;
      height: 50px;
    }
    // .head-portrait-title{
    //   width: 95px;
    //   height: 22px;
    //   margin-left: 8px;
    // }
  }
  // 首页按钮
  .home-menu-home {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #1e2b4f;
    line-height: 22px;
    margin-left: 84px;
  }
  //运费查询
  .home-menu-find {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #1e2b4f;
    line-height: 22px;
    margin-left: 82px;
  }
  .home-menu {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #1e2b4f;
    line-height: 22px;
    margin-left: 82px;
  }
  // 菜单选中态
  .active {
    color: #6d5efe;
    font-weight: bold;
  }
  // SD 搜索框
  .SD-track {
    width: 250px;
    height: 44px;
  }
  // SD 搜索按钮
  .track-btn {
    width: 94px;
    height: 44px;
    background: #6d5efe;
    border-radius: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
    margin-left: 20px;
  }
  //登陆和注册
  .loginOrRegDev {
    width: 91px;
    height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 74px;
    // 登录注册样式
    .loginOrReg {
      font-size: 16px;
      font-weight: 400;
      color: #1e2b4f;
      line-height: 22px;
      display: inline-block;
      text-align: center;
    }
    // 中间竖线
    .ver {
      color: #d2d5dc;
      font-size: 16px;
      height: 22px;
    }
  }
  //登陆后的div,状态栏的div
  .my-account-div {
    margin-left: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    //我的账户按钮
    .my-account {
      width: 81px;
      height: 36px;
      background: #ffffff;
      border-radius: 22px;
      border: 1px solid #6d5efe;
      font-size: 14px;
      font-weight: 400;
      color: #6d5efe;
      line-height: 34px;
      text-align: center;
    }
    //购物车icon
    .shopping-cart-icon {
      width: 20px;
      height: 21px;
      margin-left: 30px;
    }
    .shopping-cart-icon:hover img {
      content: url(../../assets/img/home/icon_shopping_cart_hov.png);
    }
    //头像icon
    .my-account-icon {
      width: 32px;
      height: 32px;
      margin-left: 30px;
    }
  }
}
</style>
